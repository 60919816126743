import { memo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Dropdown, Menu, Avatar } from 'antd'
import { UserOutlined, DownOutlined, LogoutOutlined, LockOutlined } from '@ant-design/icons'
import { logout } from '@/services/admin'
import ChangePassword from './ChangePassword'
import storage from '@/helper/storage'

import styles from './style.module.less'

const menus = [
  {
    key: 'password',
    label: '修改密码',
    icon: <LockOutlined />,
  },
  {
    key: 'logout',
    label: '退出登录',
    icon: <LogoutOutlined />,
  },
]

function Toolbar() {
  const location = useLocation()
  const navigate = useNavigate()
  const user = storage.get('user') as nxkb.UserData
  const [changePwdVisible, setChangePwdVisible] = useState(false)

  const handleMenuClick = async (key: string) => {
    if (key === 'logout') {
      await logout()
      storage.remove('token')
      storage.remove('user')
      navigate('/login', { state: { from: location } })
    } else if (key === 'password') {
      setChangePwdVisible(true)
    }
  }

  const closeChnagePwdModal = () => {
    setChangePwdVisible(false)
  }

  return (
    <div className={styles.toolbar}>
      <span>欢迎登录~</span>
      <div>
        <Dropdown
          overlay={
            <>
              <Menu items={menus} onClick={({ key }) => handleMenuClick(key)}></Menu>
            </>
          }
        >
          <div className={styles.dropdown}>
            <Avatar className={styles.avatar} src={user.avatar} icon={<UserOutlined />} />
            <span className={styles.name}>{user.name}</span>
            <DownOutlined className={styles.arrow} />
          </div>
        </Dropdown>
        <ChangePassword visible={changePwdVisible} onClose={closeChnagePwdModal} />
      </div>
    </div>
  )
}

export default memo(Toolbar)
