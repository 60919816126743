import { Suspense, lazy } from 'react'
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import PageLoading from '@/components/PageLoading'
import PageLayout from '@/components/Layout'
import storage from '@/helper/storage'
import _ from 'lodash'

/** 登录页 */
const Login = lazy(() => import(/* webpackChunkName: "login" */ './pages/login'))
/** 数据大盘 */
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ './pages/dashboard'))
/** 管理员管理 */
const AdminList = lazy(() => import(/* webpackChunkName: "account.admin" */ './pages/account/admin/list'))
/** 用户管理 */
const UserList = lazy(() => import(/* webpackChunkName: "account.user" */ './pages/account/user/list'))
/** 角色管理 */
const RoleList = lazy(() => import(/* webpackChunkName: "account.role" */ './pages/account/role/list'))
const CreateRole = lazy(() => import(/* webpackChunkName: "account.role.create" */ './pages/account/role/create'))
const UpdateRole = lazy(() => import(/* webpackChunkName: "account.role.update" */ './pages/account/role/update'))
/** 机构管理 */
const AgencyList = lazy(() => import(/* webpackChunkName: "account.agency" */ './pages/account/agency/list'))
/** 部门管理 */
const DepartmentList = lazy(
  () => import(/* webpackChunkName: "account.department" */ './pages/account/department/list')
)

/** 课程管理 */
const CourseManagement = lazy(() => import(/* webpackChunkName: "biz.cm" */ './pages/biz/cm'))
/** 资源 */
const CreateResource = lazy(
  () => import(/* webpackChunkName: "biz.resource.create" */ './pages/biz/cm/resource/create')
)
const UpdateResource = lazy(
  () => import(/* webpackChunkName: "biz.resource.update" */ './pages/biz/cm/resource/update')
)
const ResourceDetail = lazy(
  () => import(/* webpackChunkName: "biz.resource.detail" */ './pages/biz/cm/resource/detail')
)
/** 课程 */
const CreateCourse = lazy(() => import(/* webpackChunkName: "biz.course.create" */ './pages/biz/cm/course/create'))
const UpdateCourse = lazy(() => import(/* webpackChunkName: "biz.course.update" */ './pages/biz/cm/course/update'))
const CourseDetail = lazy(() => import(/* webpackChunkName: "biz.course.detail" */ './pages/biz/cm/course/detail'))
/** 系列 */
const CreateSerie = lazy(() => import(/* webpackChunkName: "biz.serie.create" */ './pages/biz/cm/serie/create'))
const UpdateSerie = lazy(() => import(/* webpackChunkName: "biz.serie.update" */ './pages/biz/cm/serie/update'))
const SerieDetail = lazy(() => import(/* webpackChunkName: "biz.serie.detail" */ './pages/biz/cm/serie/detail'))
/** 板块 */
const CreateCategory = lazy(
  () => import(/* webpackChunkName: "biz.category.create" */ './pages/biz/cm/category/create')
)
const UpdateCategory = lazy(
  () => import(/* webpackChunkName: "biz.category.update" */ './pages/biz/cm/category/update')
)
const CategoryDetail = lazy(
  () => import(/* webpackChunkName: "biz.category.detail" */ './pages/biz/cm/category/detail')
)

/** 考试管理 */
const ExamManager = lazy(() => import(/* webpackChunkName: "biz.em" */ './pages/biz/em'))
/** 问题 */
const CreateQuestion = lazy(
  () => import(/* webpackChunkName: "biz.question.create" */ './pages/biz/em/question/create')
)
const QuestionDetail = lazy(
  () => import(/* webpackChunkName: "biz.question.detail" */ './pages/biz/em/question/detail')
)
const UpdateQuestion = lazy(
  () => import(/* webpackChunkName: "biz.question.detail" */ './pages/biz/em/question/update')
)
/** 试卷 */
const CreateTestPaper = lazy(
  () => import(/* webpackChunkName: "biz.testpaper.create" */ './pages/biz/em/testPaper/create')
)
const UpdateTestPaper = lazy(
  () => import(/* webpackChunkName: "biz.testpaper.update" */ './pages/biz/em/testPaper/update')
)
/** 考试 */
const CreateExam = lazy(() => import(/* webpackChunkName: "biz.exam.create" */ './pages/biz/em/exam/create'))
const UpdateExam = lazy(() => import(/* webpackChunkName: "biz.exam.update" */ './pages/biz/em/exam/update'))
const ExamDetail = lazy(() => import(/* webpackChunkName: "biz.exam.detail" */ './pages/biz/em/exam/detail'))

/** 陪练管理 */
const PracticeManager = lazy(() => import(/* webpackChunkName: "biz.pm" */ './pages/biz/pm'))
/** 话术 */
const CreateTalking = lazy(() => import(/* webpackChunkName: "biz.talking.create" */ './pages/biz/pm/talking/create'))
const UpdateTalking = lazy(() => import(/* webpackChunkName: "biz.talking.update" */ './pages/biz/pm/talking/update'))
const TalkingDetail = lazy(() => import(/* webpackChunkName: "biz.talking.detail" */ './pages/biz/pm/talking/detail'))

/** 陪练 */
const CreatePractice = lazy(
  () => import(/* webpackChunkName: "biz.practice.create" */ './pages/biz/pm/practice/create')
)
const UpdatePractice = lazy(
  () => import(/* webpackChunkName: "biz.practice.update" */ './pages/biz/pm/practice/update')
)
const PracticeDetail = lazy(
  () => import(/* webpackChunkName: "biz.practice.detail" */ './pages/biz/pm/practice/detail')
)

/** 直播管理*/
const LiveList = lazy(() => import(/* webpackChunkName: "biz.live" */ './pages/biz/live/list'))
/** 添加直播*/
const LiveCreate = lazy(
  () => import(/* webpackChunkName: "biz.live.components.create" */ './pages/biz/live/components/create')
)
/** 编辑直播*/
const LiveUpdate = lazy(
  () => import(/* webpackChunkName: "biz.live.components.update" */ './pages/biz/live/components/update')
)

/** 学习地图管理 */
const LearningMapList = lazy(() => import(/* webpackChunkName: "biz.learningmap" */ './pages/biz/learningmap/list'))
const CreateLearningMap = lazy(
  () => import(/* webpackChunkName: "biz.learningmap.create" */ './pages/biz/learningmap/create')
)
const UpdateLearningMap = lazy(
  () => import(/* webpackChunkName: "biz.learningmap.update" */ './pages/biz/learningmap/update')
)
const LearningMapDetail = lazy(
  () => import(/* webpackChunkName: "biz.learningmap.detail" */ './pages/biz/learningmap/detail')
)

/** 学习日志管理 */
const StudyStatsManager = lazy(
  () => import(/* webpackChunkName: "operation.studystats" */ './pages/operation/studyStats/daily')
)

/** 轮播图管理 */
const BannerList = lazy(() => import(/* webpackChunkName: "operation.banner" */ './pages/operation/banner/list'))
/** 证书管理 */
const CertList = lazy(() => import(/* webpackChunkName: "operation.cert" */ './pages/operation/cert/list'))
/** 消息通知管理 */
const NoticeList = lazy(() => import(/* webpackChunkName: "biz.notice" */ './pages/operation/notice/list'))
const CreateNotice = lazy(
  () => import(/* webpackChunkName: "operation.notice.create" */ './pages/operation/notice/create')
)
const UpdateNotice = lazy(
  () => import(/* webpackChunkName: "operation.notice.update" */ './pages/operation/notice/update')
)
/** 评论管理 */
const CommentsManage = lazy(
  () => import(/* webpackChunkName: "operation.comments" */ './pages/operation/comments/list')
)
/** 评论详情 */
const CommentsDetail = lazy(
  () => import(/* webpackChunkName: "operation.manage.detail" */ './pages/operation/comments/manage/detail')
)
/** 意见反馈 */
const FeedbackList = lazy(() => import(/* webpackChunkName: "operation.feedback" */ './pages/operation/feedback/list'))
/**申请试用 */
const TrialList = lazy(() => import(/* webpackChunkName: "operation.trial" */ './pages/operation/trial/list'))
/** 评论管理 */
const ReportList = lazy(() => import(/* webpackChunkName: "operation.report" */ './pages/operation/report/list'))
/** 知识分类 */
const TagList = lazy(() => import(/* webpackChunkName: "operation.tag" */ './pages/operation/tag/list'))
/** App版本管理 */
const VersionList = lazy(() => import(/* webpackChunkName: "operation.version" */ './pages/operation/version/list'))
/** 404页面 */
const NotFound = lazy(() => import(/* webpackChunkName: "404" */ './pages/errors/404'))

function Protected({ children }: { children: JSX.Element }) {
  const location = useLocation()
  return (
    <Suspense fallback={<PageLoading />}>
      {!_.isNull(storage.get('token')) ? children : <Navigate to="/login" state={{ from: location }} />}
    </Suspense>
  )
}

function Router() {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<PageLoading />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Protected>
              <PageLayout />
            </Protected>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="account">
            <Route path="admin">
              <Route index element={<AdminList />} />
            </Route>
            <Route path="role">
              <Route index element={<RoleList />} />
              <Route path="create" element={<CreateRole />} />
              <Route path="update" element={<UpdateRole />} />
            </Route>
            <Route path="agency" element={<AgencyList />} />
            <Route path="department" element={<DepartmentList />} />
            <Route path="user" element={<UserList />} />
          </Route>
          <Route path="biz">
            <Route path="cm">
              <Route index element={<CourseManagement tab="resource" />} />
              <Route path="resource">
                <Route index element={<CourseManagement tab="resource" />} />
                <Route path="create" element={<CreateResource />} />
                <Route path="update" element={<UpdateResource />} />
                <Route path="detail" element={<ResourceDetail />} />
              </Route>
              <Route path="course">
                <Route index element={<CourseManagement tab="course" />} />
                <Route path="create" element={<CreateCourse />} />
                <Route path="update" element={<UpdateCourse />} />
                <Route path="detail" element={<CourseDetail />} />
              </Route>
              <Route path="serie">
                <Route index element={<CourseManagement tab="serie" />} />
                <Route path="create" element={<CreateSerie />} />
                <Route path="update" element={<UpdateSerie />} />
                <Route path="detail" element={<SerieDetail />} />
              </Route>
              <Route path="category">
                <Route index element={<CourseManagement tab="category" />} />
                <Route path="create" element={<CreateCategory />} />
                <Route path="update" element={<UpdateCategory />} />
                <Route path="detail" element={<CategoryDetail />} />
              </Route>
            </Route>
            <Route path="em">
              <Route index element={<ExamManager tab="question" />} />
              <Route path="question">
                <Route index element={<ExamManager tab="question" />} />
                <Route path="create" element={<CreateQuestion />} />
                <Route path="update" element={<UpdateQuestion />} />
                <Route path="detail" element={<QuestionDetail />} />
              </Route>
              <Route path="testpaper">
                <Route index element={<ExamManager tab="testpaper" />} />
                <Route path="create" element={<CreateTestPaper />} />
                <Route path="update" element={<UpdateTestPaper />} />
              </Route>
              <Route path="exam">
                <Route index element={<ExamManager tab="exam" />} />
                <Route path="create" element={<CreateExam />} />
                <Route path="update" element={<UpdateExam />} />
                <Route path="detail" element={<ExamDetail />} />
              </Route>
            </Route>
            <Route path="pm">
              <Route index element={<PracticeManager tab="talking" />} />
              <Route path="talking">
                <Route index element={<PracticeManager tab="talking" />} />
                <Route path="create" element={<CreateTalking />} />
                <Route path="update" element={<UpdateTalking />} />
                <Route path="detail" element={<TalkingDetail />} />
              </Route>
              <Route path="practice">
                <Route index element={<PracticeManager tab="practice" />} />
                <Route path="create" element={<CreatePractice />} />
                <Route path="update" element={<UpdatePractice />} />
                <Route path="detail" element={<PracticeDetail />} />
              </Route>
            </Route>
            <Route path="live">
              <Route index element={<LiveList />} />
              <Route path="create" element={<LiveCreate />} />
              <Route path="update" element={<LiveUpdate />} />
            </Route>
            <Route path="learningmap">
              <Route index element={<LearningMapList />} />
              <Route path="create" element={<CreateLearningMap />} />
              <Route path="update" element={<UpdateLearningMap />} />
              <Route path="detail" element={<LearningMapDetail />} />
            </Route>
          </Route>
          <Route path="operation">
            <Route path="studystats" element={<StudyStatsManager />} />
            <Route path="tag" element={<TagList />} />
            <Route path="banner" element={<BannerList />} />
            <Route path="cert" element={<CertList />} />
            <Route path="notice">
              <Route index element={<NoticeList />} />
              <Route path="create" element={<CreateNotice />} />
              <Route path="update" element={<UpdateNotice />} />
            </Route>
            <Route path="report" element={<ReportList />} />
            <Route path="comments">
              <Route index element={<CommentsManage />} />
              <Route path="detail" element={<CommentsDetail />} />
            </Route>
            <Route path="version" element={<VersionList />} />
            <Route path="feedback" element={<FeedbackList />} />
            <Route path="trial" element={<TrialList />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="404" element={<NotFound />} />
      </Routes>
    </HashRouter>
  )
}

export default Router
