import { Suspense, useState, memo } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import PageLoading from '../PageLoading'
import SiderMenu from './SiderMenu'
import Toolbar from './Toolbar'

import styles from './style.module.less'

const { Header, Sider } = Layout

function PageLayout() {
  const [collapsed, setCollapsed] = useState(false)

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed)
  }

  return (
    <Layout className={styles.layout}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className={[styles.title, collapsed ? styles.collapsed : ''].join(' ')}>
          <span className={styles.logo}></span>
          <h3>农信挎包管理后台</h3>
        </div>
        <SiderMenu />
      </Sider>
      <Layout className={styles.container}>
        <Header className={styles.header}>
          <Toolbar />
        </Header>
        <Suspense fallback={<PageLoading />}>
          <Outlet />
        </Suspense>
      </Layout>
    </Layout>
  )
}

export default memo(PageLayout)
