import { Fragment, useEffect, memo } from 'react'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'

function PageLoading() {
  useEffect(() => {
    nprogress.start()
    return () => {
      nprogress.done()
    }
  }, [])
  return <Fragment></Fragment>
}

export default memo(PageLoading)
