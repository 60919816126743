import axios from 'axios'
import { message } from 'antd'
import storage from './storage'
import { API_BASE_URL } from '../config'
import _ from 'lodash'

axios.defaults.baseURL = API_BASE_URL
axios.defaults.withCredentials = false

const token = storage.get('token')
if (!_.isNull(token)) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status >= 400) {
      if (err.response.status === 401) {
        storage.remove('token')
        delete axios.defaults.headers.common.Authorization
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      }
      message.error(err.response.data.message)
      return Promise.reject(err.response.data)
    }
    return Promise.reject(err)
  }
)

export default axios
