import request from '@/helper/request'

type LoginData = nxkb.LoginData<nxkb.UserData>

/**
 * 用户账号密码登录接口
 * @param telephone 手机号
 * @param password 密码
 * @returns token 和用户信息
 */
export async function login(username: string, password: string) {
  return request.post<LoginData>('/admin/login', { username, password }).then((resp) => {
    request.defaults.headers.common.Authorization = `Bearer ${resp.data.token}`
    return resp.data
  })
}

/**
 * 用户登出接口
 * @returns 是否登出成功
 */
export async function logout() {
  return request.post<nxkb.ResponseStatus>('/admin/logout').then((resp) => {
    delete request.defaults.headers.common.Authorization
    return resp.data
  })
}

/**
 * 用户修改密码接口
 * @returns 是否修改成功
 */
export async function password(oldPwd: string, newPwd: string) {
  return request.put<nxkb.ResponseStatus>('/user/password', { oldPwd, newPwd }).then((resp) => resp.data)
}

/**
 * 创建管理员
 * @param payload 管理员数据
 * @returns 管理员信息
 */
export async function create(payload: nxkb.UserSchema) {
  return request.post<nxkb.UserSchema>('/admins', payload).then((resp) => resp.data)
}

/**
 * 获取管理员信息
 * @param id 管理员 id
 * @returns 管理员信息
 */
export async function query(id: string) {
  return request.get<nxkb.UserData>(`/admins/${id}`).then((resp) => resp.data)
}

/**
 * 更新管理员信息
 * @param id 管理员 id
 * @param payload 管理员信息
 * @returns 管理员信息
 */
export async function update(id: string, payload: Partial<nxkb.UserSchema>) {
  return request.put<nxkb.UserSchema>(`/admins/${id}`, payload).then((resp) => resp.data)
}

/**
 * 删除管理员信息
 * @param id 管理员 id
 * @returns 是否删除成功
 */
export async function remove(id: string) {
  return request.delete<nxkb.UserSchema>(`/admins/${id}`).then((resp) => resp.data)
}

/**
 * 获取管理员列表
 * @param params 查询参数
 * @returns 管理员列表数据
 */
export async function list(params: Partial<nxkb.UserSchema & nxkb.QueryParams> = {}) {
  return request.get<nxkb.ListResponse<nxkb.UserData>>('/admins', { params }).then((resp) => resp.data)
}
