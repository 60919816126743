import { useState } from 'react'
import { Modal, Form, Input, message } from 'antd'
import { password } from '@/services/admin'

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
}

interface FormProps {
  visible: boolean
  onClose: () => void
}

function PasswordFrom(props: FormProps) {
  const { visible, onClose } = props
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const handleFinish = (values: any) => {
    if (values.oldpwd === values.newpwd) {
      message.warning('新旧密码相同')
      return false
    }
    if (values.newpwd !== values.renewpwd) {
      message.warning('两次密码不一致')
      return false
    }
    setLoading(true)
    password(values.oldpwd, values.newpwd)
      .then((res) => {
        if (res.status === 'success') {
          message.success(res.message)
        }
        onClose()
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Modal
      title="密码修改"
      maskClosable={false}
      visible={visible}
      confirmLoading={loading}
      onOk={() => form.submit()}
      onCancel={onClose}
      afterClose={() => form.resetFields()}
    >
      <Form {...formItemLayout} preserve={false} form={form} onFinish={handleFinish}>
        <Form.Item name="oldpwd" label="旧密码" required rules={[{ required: true }]}>
          <Input.Password placeholder="请输入你的旧密码" />
        </Form.Item>
        <Form.Item name="newpwd" label="新密码" required rules={[{ required: true, message: '请输入你的新密码！' }]}>
          <Input.Password minLength={8} placeholder="请输入你的新密码！" />
        </Form.Item>
        <Form.Item name="renewpwd" label="确认密码" required rules={[{ required: true }]}>
          <Input.Password minLength={8} placeholder="请再次输入你的新密码" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PasswordFrom
