import { useMemo, memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import { UserOutlined, ReadFilled, SlidersFilled, PieChartOutlined } from '@ant-design/icons'

import './sider.module.less'

const menus = [
  {
    key: '/',
    label: '数据看板',
    icon: <PieChartOutlined />,
  },
  {
    label: '账号管理',
    key: '/account',
    icon: <UserOutlined />,
    children: [
      {
        label: '管理员',
        key: '/account/admin',
      },
      {
        label: '角色',
        key: '/account/role',
      },
      {
        label: '机构',
        key: '/account/agency',
      },
      {
        label: '用户',
        key: '/account/user',
      },
      {
        label: '部门',
        key: '/account/department',
      },
    ],
  },
  {
    label: '业务板块',
    key: '/biz',
    icon: <ReadFilled />,
    children: [
      {
        label: '课程管理',
        key: '/biz/cm',
      },
      {
        label: '考试管理',
        key: '/biz/em',
      },
      {
        label: '陪练管理',
        key: '/biz/pm',
      },
      {
        label: '直播管理',
        key: '/biz/live',
      },
      {
        label: '学习地图',
        key: '/biz/learningmap',
      },
    ],
  },
  {
    label: '运营板块',
    key: '/operation',
    icon: <SlidersFilled />,
    children: [
      {
        label: '学习记录',
        key: '/operation/studystats',
      },
      {
        label: '消息通知',
        key: '/operation/notice',
      },
      {
        label: '举报管理',
        key: '/operation/report',
      },
      {
        label: '评论管理',
        key: '/operation/comments',
      },
      {
        label: '知识分类',
        key: '/operation/tag',
      },
      {
        label: '证书',
        key: '/operation/cert',
      },
      {
        label: '轮播图',
        key: '/operation/banner',
      },
      {
        label: 'App版本',
        key: '/operation/version',
      },
      {
        label: '意见反馈',
        key: '/operation/feedback',
      },
      {
        label: '申请试用',
        key: '/operation/trial',
      },
    ],
  },
]

function SiderMenu() {
  const location = useLocation()
  const navigate = useNavigate()
  const selectedKeys = useMemo(() => {
    let path = ''
    const paths: string[] = []
    location.pathname.split('/').forEach((item) => {
      if (item) {
        path += `/${item}`
        paths.push(path)
      }
    })
    return paths.length === 0 ? ['/'] : paths
  }, [location])

  return (
    <Menu
      theme="dark"
      items={menus}
      onClick={({ key }) => navigate(key)}
      defaultSelectedKeys={location.pathname ? selectedKeys : ['/']}
      defaultOpenKeys={location.pathname ? selectedKeys : []}
      mode="inline"
    />
  )
}

export default memo(SiderMenu)
