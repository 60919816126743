import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import Router from './router'
import pkg from '../package.json'
import zhCN from 'antd/es/locale/zh_CN'
import 'dayjs/locale/zh-cn'

import './styles/global.less'

dayjs.locale('zh-cn')

console.info(`当前版本：v${pkg.version}`)

const root = createRoot(document.getElementById('root') as HTMLElement)
// 装载
root.render(
  <RecoilRoot>
    <ConfigProvider locale={zhCN}>
      <Router />
    </ConfigProvider>
  </RecoilRoot>
)
